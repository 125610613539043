import React, {useEffect, useState} from "react";
import { navigate, Link } from 'gatsby'


const NotFoundPage = () => {
	let redirect = () => navigate('/')
	let [count, counting] = useState(5)

	useEffect(() => {
		setTimeout(function() { 
			redirect() 
		}, 3000);
	}, []);

// 	useEffect(() => {
// 		let timer = setInterval(() => {
// 			let  counter  = count;
// 				counting(counter-1)
// 		}, 1000)

// // with return cleaning the interval before component will unmount
// 		return function clearinterval() {
// 			clearInterval(timer)
// 		};

// 	})


	return (
		<div className='transparentContainer flex900'>
		<h1>This page is not found.</h1>
		<h2>Sorry...</h2>
		<p><Link to="/">Go to the homepage instead?</Link></p>
		{/*<p>Taking you to the homepage instead...homepage in {count} {count == 1 ? 'second' : 'seconds'}</p>*/}
		</div>)
}

export default NotFoundPage;
